<template>
  <div class="contents">
      <div class="return" @click="returnTap">{{$t('N_I_241')}}</div>
      <div class="content-width">
        <div class="width">
            <img class="img-width" src="@/assets/images/markerSpace/bg-color.png" />

            <div class="title">荣誉馆</div>
            <div class="honnor-content">
                <div class="honorList" v-for="item in bannerLists" :class="item.isHave==1?'class1':''">
                    <div class="img">
                        <img :src="item.img"/>
                    </div>
                    <div class="name sizes" :style="item.isHave==1?'color:#2E1C10':''">{{item.title}}</div>
                    <div class="name" v-if="item.isHave==0">{{item.haveTime}}</div>
                </div>
            </div>
        </div>
      </div>
      <div>
          <pagination
              v-if="total>0"
              :total="total"
              :pageSizes="[14]"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getBankCardChoiceList"
          />
      </div>
  </div>
</template>

<script>
import * as sid from "@/api/sidebaruserinfo.js";
import sidebarUserInfo from "@/components/sidebarUserInfo";
import topBanner from "@/components/topBanner";
import markerSpace from "@/components/markerSpace";
import * as api from "@/api/register";
import { getBankCardChoiceList } from "@/api/wallet";
import { getToken } from "@/util/auth";
import {getMemberHonorWall, getMemberHonorWallAll} from "@/api/sidebaruserinfo.js";
export default {
  data() {
    return {
        bannerLists:[],
        total:0,
        queryParams: {
            pageNum: 1,
            pageSize: 14,
        },
    };
  },
  components: { },
  watch: {},
  mounted() {
    this.getBankCardChoiceList();
  },
  methods: {
      returnTap(){
          this.$router.go(-1)
      },
    getBankCardChoiceList() {
        sid.getMemberHonorWallAll(this.queryParams).then((res) => {
            this.total = res.total
            this.bannerLists = res.rows;
        });
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .pagination-container{
  background: none !important;
}
.class1{
  background: #E6E6E6!important;
  box-shadow: inset 0px 0px 40px 0px #A8A8A8!important;
}
.sizes{
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.img{
  img{
    width: 170px;
    height: 170px;
  }
}
.width{
  position: relative;
  width: 100%;
}
.img-width{
  width: 100%;
  height: 948px;
  //height: 100%;
  //height: ;
  position: absolute;
}
.honorList{
  margin-left: 20px;
  width: 177px;
  height: 240px;
  background: #F5CB90;
  box-shadow: inset 0px 0px 40px 0px #BA7A12;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
  padding: 40px 30px;
  text-align: center;
    //height: fit-content;
}
.honnor-content{
  margin-left: 50px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  height: 500px;
}
.title{
  font-size: 50px;
  margin: 40px auto;
  text-align: center;
  color: #74511F;
}
.pagination-container{
    justify-content: center;
    align-items: center;
    display: flex;
    background: #fff;
  position: relative;
  padding: 10px;
}
  .tops{
    margin-top: 60px;
  }
  .market-info{
    display: flex;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .name{
    color: #7A3A0B;
    font-size: 14px;
    margin-left: 8px;
  }
  .market-icon{
    img{
      width: 18px;
      height: 18px;
    }
  }
  .marketImg{
    position: absolute;
    right: 20px;
    top: 20px;
    img{
      width: 150px;
      height: 150px;
    }
  }
  .marketName{
    color: #333;
    font-size: 28px;
    font-weight: 600;
  }
  .return{
    cursor: pointer;
    width: 98px;
    height: 48px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    z-index: 1;
    color: #74511F;
    font-size: 16px;
    margin-left: 60px;
    text-align: center;
    line-height: 48px;
    margin-top: 50px;
    position: absolute;
    background: url('~@/assets/images/markerSpace/bg-color1.png')no-repeat ;

  }
  .content-width{
    //padding: 20px 100px 20px 80px;
    display: flex;
    flex-wrap: wrap;
    //background: url('~@/assets/images/markerSpace/bg-color.png')no-repeat ;
    width: 100%;
    //height: 948px;
    position: relative;
      height: 828px;


  }
  .marketList{
    position: relative;
    width: 500px;
    //height: 205px;
    background: #FFFFFF;
    box-shadow: inset 0px 0px 40px 0px #DBDBDB, 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 10px 10px 10px 10px;
    padding: 20px 25px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
</style>
